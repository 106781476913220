<template>
  <ProductListHorizontal
    :title="props.title"
    :product-list="props.productList"
    :show-categories="false"
    :breakpoints="props.breakpoints"
    :slides-per-view="props.slidesPerView"
    is-image-path
    virtual
    is-watched-product
  />
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: 'Супутні товари'
  },
  productList: {
    type: Array,
    default: () => []
  },
  slidesPerView: {
    type: [Number, String],
    default: 2
  },
  breakpoints: {
    type: Object,
    default: () => ({
      400: {
        slidesPerView: 2
      },
      570: {
        slidesPerView: 3
      },
      800: {
        slidesPerView: 4
      }
    })
  }
})
</script>
