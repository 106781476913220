<template>
  <div
    :id="'product-cart-promotion-item-' + variant?.code"
    class="grid px-20 grid-cols-[1fr_auto] lg:grid-cols-[auto_auto_auto] gap-[10px] lg:gap-[46px] bg-white items-start lg:items-center min-h-[86px] p-4"
  >
    <div class="flex gap-[14px] items-center">
      <div class="min-w-[60px] flex justify-center">
        <nuxt-img
          v-if="variant?.images[0]?.path"
          :src="variant?.images[0]?.path"
          format="webp"
          loading="lazy"
          class="max-h-[53px] w-[60px] object-contain"
        />
      </div>
      <div class="grid gap-2.5">
        <div
          class="line-clamp-2 text-[11px] lg:text-[13px] font-bold lg:leading-[17px]"
        >
          {{ variant?.name }}
        </div>
        <div class="text-[11px] lg:text-[12px] text-gray-mud">
          {{ t('Код товару') }}: {{ variant?.code }}
        </div>
        <div v-if="item?.hasDiscountLimit" class="text-red-hot text-[11px]">
          {{ t('Товар з обмеженою знижкою') }}
        </div>
      </div>
    </div>

    <div class="flex gap-[14px] items-center row-span-2 lg:row-span-1">
      <div>
        <div v-if="item?.getDiscount()" class="space-x-1 text-[12px]">
          <span class="line-through decoration-primary">{{
            item?.getFormattedOriginalUnitPrice()
          }}</span>
          <span class="px-1.5 bg-red-hot/20">-{{ item?.getDiscount() }}%</span>
        </div>
        <div
          :id="'cart-price-item-' + variant?.code"
          class="font-bold text-[19px]"
          :class="{ 'text-primary': item?.getDiscount() }"
        >
          {{ item?.getFormattedDiscountedUnitPrice() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import debounce from 'lodash/debounce'
import OrderItemEntity from '~~/entities/OrderItem.entity'
import { useCartStore } from '~~/stores/cart'

const { $gtagEvent } = useNuxtApp()

const cartStore = useCartStore()
const { t } = useI18n()
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})
const loading = inject('loading')
const item = computed(() => new OrderItemEntity(props.item))
const variant = computed(() => item.value?.variant)

const quantity = ref(item.value.quantity)
watch(
  () => quantity.value,
  debounce((v, oldV) => {
    return cartStore.changeQuantity(props.item.id, v, {
      onRequest: () => (loading.value = true),
      onSuccess: () => {
        try {
          return v > oldV
            ? $gtagEvent.addToCart({
              ...item.value,
              quantity: v - oldV
            })
            : $gtagEvent.removeFromCart({
              ...item.value,
              quantity: oldV - v
            })
        } catch (e) {
          console.error(e)
        }
      },
      onFinally: () => (loading.value = false)
    })
  }, 700)
)
</script>

<style lang="postcss" scoped>
.menu-link {
  @apply px-4 py-2 flex items-center gap-3;
}
</style>
